import * as React from "react"
import Header from "../components/header"

import Layout from "../components/layout"
import Seo from "../components/seo"

import './privacy-policy.css'

const PrivacyPolicy = () => (
    <Layout>
        <Seo title="Privacy Policy" />

        <Header />

        <div className="text-page-hero">
            <div className="container">
                <h1>Privacy Policy</h1>
            </div>
        </div>
        <div className="text-page-body">
            <div className="container">
                <p><strong>Personal data</strong></p>



                <ol><li>The Administrator of personal data provided by the user of the website www.axtelheadsets.com (hereinafter: the User), for marketing purposes on the basis of the contact form found on this website is Axtel Sp. z o.o. sp. K., with its registered office in Warsaw (02-675) in the Mokotow Box building at 16 Wołoska Street, registered by the District Court for the capital city of Warsaw under KRS number 0000710878, Tax Identification Number (NIP) 5213675055, REGON 147316060.</li><li>If the User provides information about his personal data, such as name, surname, address, e-mail address, telephone and fax numbers or other identifying information, such an information will not be shared (for shared use, for sale or as part of Adinistrator’s contacts) to third parties, unless the Administrator have been authorized to do so by the User or the obligation to disclose such a&nbsp;data under the applicable law.</li></ol>



                <p><strong>Privacy and security</strong></p>



                <ol><li>The Administrator attaches great importance to ensuring the security of personal data, i.e.&nbsp;name, address, telephone number or e-mail address. The activity is carried out in&nbsp;accordance with applicable law, including laws on data protection and security.</li><li>The data transferred via e-mail correspondence is also protected.</li><li>The User decides on himself whether and what data should be made available to the Administrator expressing his / her voluntary consent.</li><li>The Administrator protects User’s data against loss, damage, falsification, manipulation, unauthorized access and disclosure. The administrator guarantees the insight and the ability to verify provided personal data.</li><li>The Administrator uses User’s personal data to send him/her an offer for his products and services.</li></ol>



                <p><strong>Data modification and cancellation of consent by the User</strong></p>



                <ol><li>As a User You are entitled at any time to:</li></ol>



                <ul><li>request information about the purpose, scope and method of processing your personal data;</li><li>access to your personal data;</li><li>changing these data;</li><li>full removal of your personal data (right to be forgotten);</li><li>transfer your data to other entity;</li><li>requests discontinuation of processing your personal data.</li></ul>



                <ol><li>Exercising these rights is possible via:</li></ol>



                <ul><li>phone at no. (+48 22) 490 96 00</li><li>e-mail at:&nbsp;<a href="mailto:marketing@axtelheadsets.com">marketing@axtelheadsets.com</a>;</li><li>in writing to the following address: 16 Woloska St., 02-675 Warsaw, Poland.</li></ul>



                <p><strong>Legal basis and supervision authority</strong></p>



                <ol><li>All personal data provided by the User will be processed for marketing purposes on the basis on art. 6 par. 1 letter a – b and f of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</li><li>In the event of violation of the law on processing personal data, the User may submit a&nbsp;complaint to the competent supervisory authority, which in the case of the Administrator is&nbsp;the President of the Office for the Protection of Personal Data, at the address: Office of for the Protection of Personal Data, ul. Stawki 2, 00-193 Warsaw, Poland; T: (+48) 22 531 03 00; E:&nbsp;<a href="mailto:kancelaria@giodo.gov.pl">kancelaria@giodo.gov.pl</a>; F: (+48) 22 531 03 01.</li></ol>



                <p><strong>Cookie files</strong></p>



                <ol><li>Cookies are files that provide information storage or access to information already stored in&nbsp;the telecommunications terminal equipment of the final User during or after visiting Internet pages, including the webpages of the Website.</li><li>Cookies used on Administrator’s site do not store any personal data, are used, among others, to remember User’s preferences, limit the display of pop-ups on the website and its security. Some functionalities on our site may not work if you do not agree to the installation of cookies.</li><li>Installing Cookies or obtaining access to them does not change the User’s telecommunications terminal equipment and software installed on his/hers device.</li></ol>



                <p><strong>What kind of cookies do we use?</strong></p>



                <ol><li>The Administrator’s website uses two basic types of cookies: session cookies and persistent cookies.</li><li>Session cookies are temporary files that are stored on the User’s end device until logging out, leaving the website or turning off the software (web browser).</li><li>Persistent cookies are files that are saved on the device used by the User, even after leaving the page being viewed.</li><li>The purpose of storing and accessing this information by the Administrator is, in particular for:</li></ol>



                <ul><li>creating statistics that help to understand how Users of the Administrator’s website use websites, which allows improving its structure and content;</li><li>maintaining the User’s session of the Administrator’s website (after logging in), thanks to which the User does not have to re-enter his/her login and password on every subpage of the Website;</li><li>defining the User’s profile in order to display him/her tailored materials in advertising networks, in particular the Google network.</li></ul>



                <ol><li>Main cookies files that we use are:</li></ol>



                <ul><li>Google Analytics</li><li>Hotjar</li></ul>



                <p><strong>How can you refuse approval to install cookies using browser settings?</strong></p>



                <p>The vast majority of browsers by default accept cookies in order to ensure User convenient usage of Internet pages and their correct display.</p>



                <p>The User who does not want cookies to be installed in his/her telecommunications terminal device, may refuse to install cookies in the following way</p>



                <ol><li>Internet Explorer browser: select “Tools / Internet options” from the main menu and then in the “Privacy” section, select “Advanced” and disable cookies;</li><li>Mozilla Firefox browser: select “Tools” from the menu and then “Options” and specify the level of privacy in the “Privacy” section;</li><li>Opera browser: select “Tools” from the menu and then from “Preferences” select “Advanced” and then “Cookies”;</li><li>Google Chrome browser: from the menu on the right, select “Options” and then “For advanced” and “Cookie settings”.</li></ol>



                <p>The browsers mentioned above are indicated as examples. Due to the big variety of browsers used by&nbsp;Users, there may be some differences in adjusting their settings so that the installation of cookies is prevented. Usually, the information about cookies may be found in the “Tools” or “Options” menu. More detailed information in this area can be found most often on the website of the browser manufacturer.</p>



                <p><strong>Hotjar</strong></p>



                <ol><li>On our website, we use the Hotjar tool, which monitors and records the user’s behavior on the website. This tool records data about user behavior such as: page entry, navigation, page scrolling, cursor movement. Information is also collected on the location, device used, operating system, browser or cookies.</li><li>Hotjar does not collect or store personal information (including personal data) of Users. For more information about Hotjar’s privacy policy, please visit:&nbsp;<a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a>.</li><li>If you do not want your activity on the site to be measured by Hotjar, use the following link:&nbsp;<a href="https://www.hotjar.com/opt-out">https://www.hotjar.com/opt-out</a>.</li></ol>
            </div>
        </div>
    </Layout>
)

export default PrivacyPolicy
